import React from "react";
import Helmet from "react-helmet";

import Layout from "src/layouts/LayoutWhiteHeader";
import config from "data/SiteConfig";

// eslint-disable-next-line react/prefer-stateless-function
class ThanksPage extends React.Component {
	render() {
		return (
			<Layout>
				<main>
					<Helmet title={`Thanks | ${config.siteTitle}`} />
				</main>
				<div className="container m-t-xl m-b-xl">
					<div className="columns">
						<div className="column is-half">
							<h2>Thanks for getting in touch</h2>
							<p>
								You should hear from us in the next two days to
								set-up a demo.
							</p>
							<p>
								If you haven&apos;t got anything in 2 working
								days, please check your junk mail.
							</p>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}

export default ThanksPage;
